* {
  padding: 0px 10px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  height: 100vh;
  width: 100%;
  background-color: #282c34;
  background-image: linear-gradient(43deg,
      #203080 0%,
      #545592 46%,
      #393d44 100%);
  background-position:  center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#loading {
  width: 100%;
  align-content: center;
  stroke-dashoffset: 0;
  fill: white;
  fill-opacity: 50%;
  animation: loading 3s ease-in-out;
  animation-iteration-count: 2;
  animation-direction: alternate;
}

@keyframes loading {
  to {
    stroke-dasharray: 700;
    stroke-dashoffset: 700;
    fill: transparent;
  }
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Welcome {
  padding: 0px 20px;
  margin: 0;
  box-sizing: border-box;
  width: 80vw;
}

.welcome{
 padding: 10px 0px;
}

.button {
  width: auto;
  background-color: #FFFFFF;
  border: 1px solid rgba(209, 213, 219, 0.563);
  border-radius: .5rem;
  box-sizing: border-box;
  color: #111827;
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: .75rem 1rem;
  text-align: center;
  text-decoration: none #D1D5DB solid;
  text-decoration-thickness: auto;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:hover {
  background-color: rgb(168, 174, 181);
  border: 1px solid rgb(168, 174, 181);
}

.button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.button:focus-visible {
  box-shadow: none;
}